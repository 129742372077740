import React, { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { headerThemeState } from '../recoil/atoms';
import '../styles/PrivacyPolicyPage.css';

const PrivacyPolicyPage = () => {
  const [, setHeaderTheme] = useRecoilState(headerThemeState);

  useEffect(() => {
    setHeaderTheme('light');
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className='privacy-policy-page'>
      <h1 className='privacy-policy__title'>Політика конфіденційності</h1>
      <p className='privacy-policy__date'>Дата набрання чинності: <strong>01.11.2024</strong></p>
      <p>
        Компанія HelpLift (далі – "Компанія", "ми", "нас", "наш") поважає вашу конфіденційність і
        дотримується законодавства України у сфері захисту персональних даних. Ця Політика
        конфіденційності описує, які дані ми збираємо, як їх використовуємо, захищаємо та
        розкриваємо.
      </p>
      <h2 className='privacy-policy__section-title'>1. Збір та обробка персональних даних</h2>
      <p>Ми збираємо та обробляємо такі персональні дані:</p>
      <ul>
        <li>Ім’я</li>
        <li>Прізвище</li>
        <li>Контактні дані (електронна адреса, номер телефону)</li>
        <li>Інформація про ваші замовлення (типи підйомників, специфікації, тощо)</li>
        <li>Інша інформація, яку ви надаєте нам через форми зворотного зв’язку.</li>
      </ul>

      <h2 className='privacy-policy__section-title'>2. Мета обробки персональних даних</h2>
      <p>Ваші персональні дані обробляються для:</p>
      <ul>
        <li>Виконання ваших замовлень та надання послуг.</li>
        <li>Комунікації з вами (надання інформації про замовлення, відповіді на запити).</li>
        <li>Аналізу та покращення якості наших послуг.</li>
        <li>Відправлення інформаційних та рекламних матеріалів (якщо ви дали згоду).</li>
      </ul>

      <h2 className='privacy-policy__section-title'>3. Правова основа обробки персональних даних</h2>
      <p>Обробка ваших персональних даних здійснюється на основі:</p>
      <ul>
        <li>Вашої згоди на обробку персональних даних.</li>
        <li>Виконання угоди, учасником якої ви є.</li>
        <li>Виконання законодавчих вимог.</li>
      </ul>

      <h2 className='privacy-policy__section-title'>4. Захист персональних даних</h2>
      <p>
        Ми вживаємо необхідні технічні та організаційні заходи для захисту ваших
        персональних даних від несанкціонованого доступу, втрати або знищення. Наші
        співробітники ознайомлені з вимогами щодо захисту інформації та зобов’язані
        дотримуватися конфіденційності.
      </p>

      <h2 className='privacy-policy__section-title'>5. Передача даних третім особам</h2>
      <p>Ваші персональні дані можуть бути передані третім особам лише у випадках:</p>
      <ul>
        <li>Якщо це необхідно для виконання ваших замовлень (наприклад, для доставки).</li>
        <li>Якщо ви надали свою згоду на таку передачу.</li>
        <li>Якщо це передбачено чинним законодавством України.</li>
      </ul>

      <h2 className='privacy-policy__section-title'>6. Ваші права</h2>
      <p>Ви маєте право:</p>
      <ul>
        <li>Запитувати доступ до ваших персональних даних.</li>
        <li>Вимагати виправлення або видалення ваших персональних даних.</li>
        <li>Вимагати обмеження обробки ваших персональних даних.</li>
        <li>Оскаржувати обробку ваших даних у відповідному органі.</li>
      </ul>

      <h2 className='privacy-policy__section-title'>7. Зміни в політиці конфіденційності</h2>
      <p>
        Ми можемо оновлювати цю Політику конфіденційності. Усі зміни набирають чинності з
        моменту їх публікації на сайті. Рекомендуємо періодично переглядати цю Політику.
      </p>

      <h2 className='privacy-policy__section-title'>8. Контактна інформація</h2>
      <p>
        Якщо у вас виникли запитання або пропозиції щодо цієї Політики конфіденційності,
        будь ласка, звертайтеся до нас за контактними даними, вказаними на нашому сайті.
      </p>
    </div>
  );
};

export default PrivacyPolicyPage;
